<script setup>
import { ref, computed, toRefs, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { subscriptionPeriod } from '@/helpers/tonos/index'
import {
  formatPadding,
  formatHeight,
  formatWidth,
} from '~~/models/layout/style'
import { playerLocked, updatePlayerLocked } from '@/helpers/player/index'
import { usePageBuilder } from '~/stores/page-builder'
import { useAuthBuilder } from '~/stores/auth-builder'
import { emitter } from '~/utils/emitter'
import {
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
} from '@/utils/webStorage'
import {
  fetchOfferByCampaign,
  getStripePublicKey,
} from '@/utils/stripeAndTonos'

// ----- PROPS -----
const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  rowOrder: {
    type: Number,
    required: false,
  },
  index: {
    type: Number,
    required: false,
    default: 1,
  },
})

// Extract props
const { data, index } = toRefs(props)

// ----- ROUTER & STORE -----
const router = useRouter()
const pageBuilderStore = usePageBuilder()
const authBuilderStore = useAuthBuilder()
const { userTonosAccess, userToken } = storeToRefs(authBuilderStore)
const { pageType, content, media, getLayoutMediaData } =
  storeToRefs(pageBuilderStore)

const hashedId = ref(null)
const checkoutDetails = ref(null)
const offerHasDetails = ref(true)

const showOfferMedia = ref(
  pageType.value === 'media' ? !data.value.props.syncWithPlayer : true
)

// ----- COMPUTED STYLES -----
const style = computed(() => ({
  ...formatPadding(data.value.style?.padding),
  height: formatHeight(data.value.style?.height),
  width: formatWidth(data.value.style?.width),
  color: data.value?.style?.color,
}))

// ----- SUBSCRIPTION CHECK -----
// Convert productId to a number to ensure correct comparison
const productId = computed(() => {
  if (pageType.value === 'content' && data.value.props.componentType === 2) {
    return Number(content.value?.customProperties.tonos_product_id)
  }
  if (pageType.value === 'media' && data.value.props.componentType === 2) {
    // emitter.emit(
    //   'ProductIdAccess',
    //   Number(relatedGroupDataCustomProperties.value?.tonos_product_id)
    // )
    return Number(relatedGroupDataCustomProperties.value?.tonos_product_id)
  }

  if (pageType.value === 'media' && data.value.props.componentType === 1) {
    // emitter.emit('ProductIdAccess', Number(data.value.props.productId))
    return Number(data.value.props.productId)
  }

  return Number(data.value.props.productId)
})

// Check if the user is already subscribed by comparing numbers directly
const isSubscribed = computed(() => {
  return userTonosAccess.value.some((item) => item === productId.value)
})

// ----- BUTTON TEXT -----
const buttonText = computed(() => {
  return userTonosAccess.value.length
    ? isSubscribed.value
      ? data.value.props?.buttonAccessText
      : data.value.props?.buttonText
    : data.value.props?.buttonText
})

// ----- REFS -----
const richtextContainer = ref(null)
let buttonElement = null

// ----- CLICK HANDLER -----
const handleButtonClick = async () => {
  if (!userToken.value) {
    const lastUrl = `${location.pathname}${location.search}`
    setSessionStorage('urlBeforeLoginRedirect', lastUrl)
    setSessionStorage('papId', hashedId.value)
    router.push('/auth/porta')
    return
  }
  if (isSubscribed.value) {
    return
  }

  try {
    const response = await pageBuilderStore.actionPutCheckoutDetails(
      checkoutDetails.value
    )

    if (!response) {
      return
    }

    if (response) {
      const lastUrl = `${location.pathname}${location.search}`
      setSessionStorage('urlBeforeLoginRedirect', lastUrl)
      if (
        typeof window !== 'undefined' &&
        window.vpanalytics &&
        typeof window.vpanalytics.initiateCheckout === 'function'
      ) {
        window.vpanalytics.initiateCheckout({
          content_name: checkoutDetails.value.name,
          items: window.location.href,
          value: checkoutDetails.value.price_number / 100,
          currency: checkoutDetails.value.currency_code,
          external_id: 3,
          orderId: checkoutDetails.value.papId,
        })
      }
      router.push({ name: 'checkout-plan', query: { plan: response } })
    } else {
      router.push('/not-found')
    }
  } catch (error) {
    console.error(error)
  }
}

// ----- UPDATE BUTTON STATE -----
const updateButton = () => {
  if (buttonElement) {
    buttonElement.classList.remove('loading-placeholder')
    buttonElement.textContent = buttonText.value

    // Remove any previous click event
    buttonElement.removeEventListener('click', handleButtonClick)

    // Add click event only if not already subscribed
    if (!isSubscribed.value) {
      buttonElement.addEventListener('click', handleButtonClick)
    }
  } else {
    console.error('Button Not Found')
  }
}

// ----- EMITTER CALLBACKS -----
const onUserFetched = () => {
  // Optionally update the button when the user data is fetched
  // updateButton()
}

const onUserNotFound = () => {
  updateButton()
}

onNuxtReady(() => {
  if (!userToken.value) {
    tryUpdateButton()
  }
})

const onUserFetchError = (error) => {
  console.error('Error fetching user:', error)
  updateButton()
}

const onTonosAccess = () => {
  updateButton()
}

function tryUpdateButton(attempt = 0) {
  // If we don't already have buttonElement, try to find it
  if (!buttonElement && richtextContainer.value) {
    buttonElement = richtextContainer.value.querySelector('button')
  }

  // If still no button, retry or give up
  if (!buttonElement) {
    if (attempt < 10) {
      setTimeout(() => tryUpdateButton(attempt + 1), 100)
    } else {
      console.error(`Button not found after multiple attempts.`)
    }
    return
  }

  // Once the button is found, run your existing update logic
  updateButton()
}

// ----- LIFECYCLE -----
onMounted(() => {
  // Find the button element inside the container
  if (richtextContainer.value && data.value?.id) {
    buttonElement = richtextContainer.value.querySelector('button')
  }

  nextTick(() => {
    emitter.emit('ProductIdAccess', productId.value)
  })
  // Listen for emitter events
  emitter.on('userFetched', onUserFetched)
  emitter.on('userNotFound', onUserNotFound)
  emitter.on('userFetchError', onUserFetchError)
  emitter.on('userTonosAccess', onTonosAccess)

  if (userToken.value) {
    tryUpdateButton()
  }
})

const relatedParents = computed(() => {
  return getLayoutMediaData.value?.parents &&
    getLayoutMediaData.value?.parents[data.value.props.groupId]
    ? getLayoutMediaData.value.parents[data.value.props.groupId]
    : null
})

const relatedGroupData = computed(() => {
  return relatedParents.value ? relatedParents.value[index.value - 1] : null
})

const relatedGroupDataCustomProperties = computed(() => {
  const customObj = relatedGroupData.value?.customProperties
    ? JSON.parse(relatedGroupData.value.customProperties)
    : null
  return customObj
})

onNuxtReady(async () => {
  let campaignPublicId
  let offerPublicId
  if (pageType.value === 'content' && data.value.props.componentType === 2) {
    // Use values from content if page type is 'content'
    campaignPublicId = content.value?.customProperties.tonos_campaign_id
    offerPublicId = content.value?.customProperties.tonos_offer_id
  } else if (
    pageType.value === 'media' &&
    data.value.props.componentType === 2
  ) {
    // Use values from relatedGroupDataCustomProperties if page type is 'media'
    campaignPublicId = relatedGroupDataCustomProperties.value?.tonos_campaign_id
    offerPublicId = relatedGroupDataCustomProperties.value?.tonos_offer_id
  } else {
    // Default to values in data.props for other cases
    campaignPublicId = data.value.props?.campaignPublicId
    offerPublicId = data.value.props?.offerId
  }

  if (!campaignPublicId && !offerPublicId) {
    console.warn('No campaignPublicId found, skipping API call')
    offerHasDetails.value = false
    return
  }

  try {
    const result = await fetchOfferByCampaign(campaignPublicId, offerPublicId)
    if (result) {
      const { offerId, firstPap, selectedOffer } = result

      checkoutDetails.value = firstPap
      hashedId.value = offerId
      if (
        (pageType.value === 'content' || pageType.value === 'media') &&
        data.value.props.componentType === 2
      ) {
        updateOfferContent(selectedOffer)
      }

      const papExist = getSessionStorage('papId')
      if (papExist === offerId) {
        handleButtonClick()
        removeSessionStorage('papId')
      }
    }
  } catch (error) {
    console.error('Error fetching offers:', error)
  }
})

watch(playerLocked, (val) => {
  if (val) {
    showOfferMedia.value = true
  }
})

function updateOfferContent(offer) {
  let price = offer.pap[0].price
  price = price.replace(/\.00/, '')
  const productName = offer.pap[0].productName
  const periodTime = subscriptionPeriod(
    offer.pap[0].timeUnitId,
    offer.pap[0].timeUnitValue,
    offer.pap[0].isRecurring
  )

  // Recursive function to try updating the DOM
  const tryUpdate = (attempt = 0) => {
    const container = document.querySelector(`#${data.value.id}`)
    if (!container) {
      if (attempt < 10) {
        // Retry after a short delay if element isn't found yet
        setTimeout(() => tryUpdate(attempt + 1), 100)
      } else {
        console.error(
          `No element found with id "${data.value.id}" after multiple attempts.`
        )
      }
      return
    }

    // Once the container is found, update its child elements
    container.querySelectorAll('.price').forEach((el) => {
      if (el) el.textContent = price
    })

    container.querySelectorAll('.period').forEach((el) => {
      if (el) el.textContent = periodTime
    })

    container.querySelectorAll('.name').forEach((el) => {
      if (el) el.textContent = productName
    })
  }

  // Use nextTick to ensure we wait for the DOM update cycle
  nextTick(() => {
    tryUpdate()
  })
}

onBeforeUnmount(() => {
  // Remove emitter listeners
  emitter.off('userFetched', onUserFetched)
  emitter.off('userNotFound', onUserNotFound)
  emitter.off('userFetchError', onUserFetchError)
  emitter.off('userTonosAccess', onTonosAccess)
  updatePlayerLocked(false)
  // Clean up button event listener
  if (buttonElement) {
    buttonElement.removeEventListener('click', handleButtonClick)
  }
})
</script>

<template>
  <div
    v-show="showOfferMedia && offerHasDetails"
    ref="richtextContainer"
    :id="data.id"
    class="richtextcontent ql-editor min-h-fit min-w-fit loading-offer-button"
    :style="style"
    :class="!data.props.showOffer ? 'hideOffer' : ''"
    v-html="data.props.html"
  />
</template>

<style scoped lang="scss">
:deep(.loading-placeholder) {
  animation: pulse 2s infinite;
  color: white !important;
  font-style: italic !important;
  cursor: not-allowed !important;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.hideOffer {
  visibility: hidden;
  max-height: 0px !important;
  opacity: 0 !important;
  overflow: hidden !important;
  min-height: unset !important;
}
</style>
